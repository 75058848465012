import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Address from "../components/address"
import "../components/layout.css"
import { Container} from "react-bootstrap";


const IndexPage = () => (
  <Layout>
    <div className="tiltedImage"> 
    <SEO title="Home" keywords={[`vankutech`, `online applications`, `web development`]} />
    <Container>
      <p className="lead">Welcome to VanKuTech WebSite! 
      <br/>The coolest software development company in Vancouver BC, Canada.
      </p>
    </Container>
    </div>
    <br/>
    <Address/>
  </Layout>
)

export default IndexPage
